.root {
  display: flex;
}

.container {
  max-width: 30px;
  min-width: 30px;
  text-align: center;
}

.clusterWrapper {
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster {
  height: 25px;
  max-width: 25px;
  min-width: 25px;
  border-radius: 20px;
  padding: 0;
  background-color: var(--CL_WHITE);
  border: 5px solid transparent;
}

.cluster:hover,
.cluster:active {
  background-color: var(--CL_WHITE) !important;
  border-color: var(--CL_GRAY_217);
}

.label {
  margin-top: -1px;
  font-weight: bold;
  font-size: 12px;
  color: var(--CL_BLACK);
  display: flex;
  justify-content: center;
}

.tooltip {
  font-size: 12px;
}

.tooltipContainer {
  margin: -3px -6px;
}

.tooltip :nth-child(1)::before {
  border-right-color: var(--PAGE_BACKGROUND_COLOR_STANDARD) !important;
}

.tooltip :nth-child(2) {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  color: var(--CL_WHITE);
  max-height: 132px;
  overflow: overlay;
}

.tooltipItem {
  display: flex;
  padding: 4px 8px;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.container {
  padding: 0;
  max-width: 15px;
  min-width: 15px;
}

.icon {
  height: 15px;
  max-width: 15px;
  border: 1px solid var(--CL_WHTE);
  border-radius: 2px;
  padding: 0;
}

.icon.operational {
  background-color: var(--CL_STATUS_OPERATIONAL);
}

.icon.degraded {
  background-color: var(--CL_STATUS_DEGRADED);
}

.icon.unhealthy {
  background-color: var(--CL_STATUS_UNHEALTHY);
}

.icon.offline {
  background-color: var(--CL_STATUS_OFFLINE);
}

.disconnectedLine {
  height: 15px;
  width: 3px;
  background-color: var(--CL_WHITE) !important;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  margin-left: 6px;
}

.tail {
  margin-left: 5px;
}
