.flightWrapper {
  height: calc(100% - 60px);
}

.infoRow {
  display: flex;
  flex: 1;
  height: 100%;
}

:global(.A321neo) .infoRow {
  height: calc(100% - var(--A321neo-LOPA) - 40px);
}

:global(.A330-941) .infoRow {
  height: calc(100% - var(--A330-941-LOPA) - 40px);
}

:global(.B767-400) .infoRow {
  height: calc(100% - var(--B767-400-LOPA) - 40px);
}

.leftContainer {
  background-color: var(--CL_GRAY_51);
  max-width: 238px;
  min-width: 238px;
  padding: 12px !important;
  height: 336px;
  margin-right: 20px;
}

.rssiTitle {
 font-size: 16px;
 font-weight: 700;
}

.key {
  height: 300px;
  padding: 20px;
  margin: auto;
}

.infoContainer {
  background-color: var(--CL_GRAY_51);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 14px;
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
}

.info {
  display: flex;
}

.note {
  max-width: 290px;
  line-height: normal;
  text-align: right;
}

.boldText {
  font-weight: 700;
}

.divider {
  border-right: 1px solid var(--CL_BORDER_PRIMARY);
  max-width: 1px;
  margin: 0 12px;
}

.rssiOverlayContainer {
  display: flex;
}

.buttonItem {
  font-size: var(--FS_MEDIUM);
  margin: 0 12px;
  display: flex;
  align-items: center;
  max-width: 150px;
  width: 150px;
  margin-top: -2px;
}

.tableContainer {
  background-color: var(--CL_GRAY_51);
  margin-bottom: 20px;
  padding: 12px 24px 24px;
}
