.root {
  width: 700px !important;
  height: inherit !important;
  padding: 10px;
}

.checked {
  background-color: var(--CL_GRAY_127);
}

.footer {
  margin-top: 15px;
  width: 100%;
  justify-content: space-between;
}

.header {
  margin-bottom: 15px;
  align-items: center;
}

.link {
  background-color: transparent;
  text-decoration: underline;
  color: var(--CL_GRAY_217);
  border: none;
}

.link:hover,
.link:active,
.link:focus {
  background-color: transparent !important;
  text-decoration: underline;
  border: none !important;
  color: var(--CL_GRAY_242) !important;
  box-shadow: none !important;
}

.listboxContainer {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  margin: 0;
  padding: 0;
}

.eventFilter {
  padding: 0 10px;
  margin-bottom: 5px;
}
