.root {
  height: 100%;
  padding-right: 0;
  margin: 0;
}

.container {
  align-items: center;
}

.label {
  margin-right: 5px;
  min-width: fit-content;
  padding: 0;
}

.root input:focus,
.root select:focus {
  outline: none !important;
}

.input {
  height: 20px;
  color: var(--CL_GRAY_242);
  padding: 0;
  min-width: 150px;
  -webkit-appearance: none;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-left: 4px;
  -moz-appearance: none;
  appearance: none;
  background: var(--CL_GRAY_95) url(../../../../assets/icons/downArrow.png) no-repeat 100% center;
  background-size: 12px;
  background-position: right 4px center;
}

.input:focus {
  background-color: var(--CL_GRAY_95);
  color: var(--CL_GRAY_242);
  outline: none !important;
}
