/* TODO: May find a better way to style this */
.root {
  height: calc(100% - 40px);
}

.refresh {
  position: absolute;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 15px;
}

.refreshIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.mapControlPanel {
  height: 40px;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  padding: 0 !important;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  overflow: hidden;
}

.button {
  display: flex;
  align-items: center;
  height: 24px;
  margin: 0 6px;
  padding: 10px 24px;
  background: var(--CL_GRAY_95);
  border: 1px solid white;
  color: var(--white);
  font-size: var(--FS_STANDARD);
  font-weight: bold;
}

.checkbox {
  margin-right: 4px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.zoomAllButton {
  padding: 10px 14px !important;
  margin-right: 16px !important;
}

.buttonContainer {
  padding-right: 24px !important;
}
