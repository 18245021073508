.root {
  height: 100%;
  padding: 15px 25px;
}

.utcColumn {
  width: 60px;
}

.lruTypeColumn {
  width: 90px;
}

.lruPositionColumn {
  width: 70px;
}

.messageColumn {
  width: calc(100% - 220px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
