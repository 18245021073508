.root {
  height: 100%;
}

.body {
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  height: 100%;
}

.bodyHeaderRow {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.fleetSelectionRow {
  background-color: var(--CL_GRAY_51);
  height: 60px;
  min-width: 1250px;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.timeframeSelectionRow {
  background-color: var(--CL_GRAY_51);
  height: 60px;
  min-width: 1250px;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.flightListRow {
  background-color: var(--CL_GRAY_51);
  padding: 0 0 0 25px;
  height: calc(100% - 120px);
}

.tableContainer {
  padding: 24px 0;
  width: 800px;
  height: 100%;
}

.secondaryTitle {
  display: flex;
  justify-content: right;
}

.titleDescription {
  white-space: pre;
  font-weight: normal;
}

.utcColumn {
  width: 190px;
}

.buttonItemLabel {
  padding-top: 0px;
}

.buttonLabel {
  height: 20px;
  display: flex;
  align-items: center;
}
