
.root {
  width: 100%;
}
.rightPanelInfo {
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  margin: 12px 24px;
  padding: 12px 0;
}

.noSpace {
  padding: 0;
}

.title {
  font-size: var(--FS_LARGE);
  margin-bottom: 10px;
}

.labels {
  padding-left: 20px;
}

.nowrap {
  white-space: nowrap;
}

strong.indent {
  margin-left: 12px;
}

.charts {
  margin: 8px;
}

.charts .col {
  padding: 0 7px;
}

.barchart {
  border: 1px solid var(--CL_BORDER_PRIMARY);
}

.chartLabel {
  display: block;
  text-align: center;
  margin-top: 5px;
}

.valignMiddle {
  display: flex;
  align-items: center;
}
