.icon {
  position: absolute;
  right: 16px;
  top: 7px;
}

.labelInput {
  align-items: center;
}

.label {
  margin-right: 15px;
}

.input {
  padding: 0;
}
