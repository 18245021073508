.root {
  height: 100%;
  flex-wrap: nowrap;
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.title {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.fleetSelectionContainer {
  background-color: var(--CL_GRAY_51);
  height: 60px;
  min-width: 1238px;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.tableContainer {
  background-color: var(--CL_GRAY_51);
  padding: 25px;
  height: calc(100% - 120px);
}

.tableTitle {
  height: 50px;
  align-items: center;
  margin-right: 14px;
  margin-bottom: 12px;
}
