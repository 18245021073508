.root {
  padding: 0 18px;
  max-width: 500px;
}

.header {
  font-size: var(--FS_LARGE);
  margin: 15px 0;
  color: var(--CL_TEXT_PRIMARY);
}

.headerValue {
  color: var(--CL_BLUE_4);
  font-weight: 600;
}

.headerLink {
  text-decoration: underline;
  cursor: pointer;
}

.buttonContainer {
  padding-right: 0;
}

.button {
  padding: 0 20px;
  height: 24px;
  margin: 0 15px 15px 0;
  font-size: var(--FS_SMALL);
}

.activeButton {
  background-color: var(--CL_GRAY_242) !important;
  border: 1px solid var(--CL_BLACK) !important;
  color: var(--CL_BLACK) !important;
}

.LRUCountContainer {
  border-top: 1px solid var(--CL_BORDER_PRIMARY);
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  padding: 12px 0 12px 12px !important;
}

.LRUCount {
  font-size: var(--FS_LARGE);
  font-weight: bold;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.buttonItemLabel {
  font-size: var(--FS_MEDIUM);
  margin: 0 12px;
}

.detailContainer {
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  padding: 12px 0 12px 12px !important;
  border-left: 1px solid var(--CL_GRAY_51);
  border-right: 1px solid var(--CL_GRAY_51);
  cursor: pointer;
}

.detailContainerActive {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  border-left: 1px solid var(--CL_BORDER_PRIMARY);
  border-right: 1px solid var(--CL_BORDER_PRIMARY);
}

.row {
  display: flex;
  align-items: center;
}

.resetBlock {
  height: 27px;
  max-width: 27px;
  margin-bottom: 4px;
  margin-left: 8px;
}

.barContainer {
  max-width: 203px;
}

.barFillSoftReset {
  background-color: var(--CL_PURPLE_1);
}

.barFillHardReset {
  background-color: var(--CL_PURPLE_2);
}

.barFillAffectedReset {
  background-color: var(--CL_GRAY_242);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.barFillAffectedResetDot {
  height: 10px;
  width: 10px;
  background-color: var(--CL_PURPLE_2);
  border-radius: 4px;
  position: absolute;
  top: 9px;
  left: 2px;
}

.numbers {
  padding-right: 0;
  font-weight: 600;
}

.highlightBarGraphLabel {
  color: var(--CL_BLUE_4);
}
