.root {
  height: 100%;
  background-color: var(--CL_GRAY_51);
  min-height: 655px;
}

.leftPanel {
  border-right: 1px solid var(--CL_BORDER_PRIMARY);
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  padding: 0;
}

.filterPanel {
  height: 230px;
}

.healthFilterPanel {
  border-top: 1px solid var(--CL_BORDER_PRIMARY);
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  height: 50px;
  align-items: center;
}

.aircraftList {
  height: calc(100% - 280px);
  position: relative;
  z-index: 0;
}

.mapPanel {
  min-width: calc(100% - 600px);
  padding: 0;
  display: flex;
  flex-direction: column;
}

.rightPanel {
  border-left: 1px solid var(--CL_BORDER_PRIMARY);
  min-width: 300px;
}
