.root {
  padding: 0 25px;
  height: 100%;
  background-color: var(--CL_GRAY_89);
}

.headerRow {
  height: 60px;
  align-items: center;
}

.headerContainer {
  font-size: var(--FS_XXLARGE);
  font-weight: 700;
  padding: 18px;
}

.filterRow {
  height: 60px;
  align-items: center;
  background-color: var(--CL_GRAY_51);
  padding: 0 20px;
  margin-bottom: 20px;
}

.tableRow {
  height: calc(100% - 140px);
}

.tableContainer {
  height: 100%;
  padding: 25px !important;
  background-color: var(--CL_GRAY_51);
}
