.root,
.info {
  min-height: 700px;
  height: 100%;
  flex-wrap: nowrap;
}

.leftPanel {
  min-width: 200px;
  max-width: 200px;
}

:global(.NARROW-BODY) .leftPanel {
  min-height: calc(var(--NARROW_BODY_LOPA_HEIGHT) + 500px);
}

:global(.WIDE-BODY) .leftPanel {
  min-height: calc(var(--WIDE_BODY_LOPA_HEIGHT) + 500px);
}

.container {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  padding: 0 25px !important;
  flex-wrap: nowrap;
  overflow: auto;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.header {
  font-size: var(--FS_XXLARGE);
  font-weight: 700;
  text-transform: capitalize;
}

.headerButton {
  margin-left: 14px;
}

.refreshButtonIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.tailChange {
  align-items: center;
}

.dataFrom {
  font-weight: bold;
  margin-right: 2px;
}
