.root {
  height: 100%;
  flex-wrap: nowrap;
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.title {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.fleetSelectionContainer {
  background-color: var(--CL_GRAY_51);
  height: 60px;
  min-width: 1238px;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.dataContainer {
  background-color: var(--CL_GRAY_51);
  padding: 25px;
  height: calc(100% - 120px);
}

.tableContainer {
  height: 100%;
}

.healthText {
  padding-left: 30px;
}

.statusIcon {
  height: 15px;
  width: 15px;
  border-radius: 2px;
  padding: 0;
  display: flex;
  align-items: center;
}

.disconnectedLine {
  height: 15px;
  width: 3px;
  background-color: var(--CL_WHITE);
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  margin-left: 6px;
}

.flightOperational {
  background-color: var(--CL_STATUS_OPERATIONAL);
}

.flightDegraded {
  background-color: var(--CL_STATUS_DEGRADED);
}

.flightUnhealthy {
  background-color: var(--CL_STATUS_UNHEALTHY);
}

.flightOffline {
  background-color: var(--CL_STATUS_OFFLINE);
}
