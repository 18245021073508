.root {
  padding: 0 18px;
}

.header {
  font-size: var(--FS_LARGE);
  margin: 15px 0;
  color: var(--CL_GRAY_242);
}

.headerValue {
  color: var(--CL_BLUE_4);
  font-weight: 600;
}

.headerLink {
  text-decoration: underline;
  cursor: pointer;
}

.LRUCountContainer {
  border-top: 1px solid var(--CL_BORDER_PRIMARY);
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  padding: 12px 0 12px 12px !important;
  margin-bottom: 24px;
}

.LRUCount {
  font-size: var(--FS_LARGE);
  font-weight: bold;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.buttonItem {
  font-size: var(--FS_MEDIUM);
  margin: 0 12px;
  display: flex;
  align-items: center;
  max-width: 100px;
}

.buttonItemLabel {
  width: 100%;
  padding-top: 4px;
  padding-left: 6px;
}

.rowContainer {
  display: flex;
  align-items: center;
}

.legendBlock {
  height: 27px;
  max-width: 27px;
  margin-bottom: 4px;
  margin-left: -64px;
}

.legendContainer {
  display: flex;
  align-items: center;
}

.legendBlockInactive {
  background-color: var(--CL_STATUS_OFFLINE);
  border: 1px solid var(--CL_GRAY_119);
}

.legendBlockIdle {
  background-color: var(--CL_WHITE);
}

.legendBlockMap {
  background-color: var(--CL_GREEN_2);
}

.legendBlockAudio {
  background-color: var(--CL_YELLOW_2);
}

.legendBlockVideo {
  background-color: var(--CL_GOLD_1);
}

.legendBlockLiveTV {
  background-color: var(--CL_ORANGE_2);
}

.legendBlockGame {
  background-color: var(--CL_BLUE_5);
}

.legendBlockUnSelected {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.bluetoothContainer {
  border-top: 1px solid var(--CL_BORDER_PRIMARY);
  padding-top: 20px;
  margin-top: 20px;
  margin-right: 150px;
  margin-left: 0px;
}

.bluetoothButtonItem {
  margin: 0;
  max-width: 146px;
  width: 116px;
}

.bluetoothIcon {
  height: 18px;
  max-width: 27px;
  margin-bottom: 4px;
  margin-left: 24px;
  margin-right: 9px;
}
