.root {
  display: flex;
  align-items: center;
}

.barContainer {
  height: 27px;
  background-color: var(--CL_GRAY_119);
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 2px;
}

.transparentBarContainer {
  height: 27px;
  background-color: transparent;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 2px;
}

.barFill {
  height: 23px;
  margin-bottom: 4px;
  border-radius: 4px;
  max-width: calc(100% - 4px);
  position: absolute;
}

.label {
  padding-right: 0;
  font-weight: 600;
}

.highlightedLabel {
  color: var(--CL_BLUE_4);
}
