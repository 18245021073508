.root {
  height: calc(100% - 60px);
}

.healthRow {
  height: 200px;
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--CL_GRAY_51);
  padding: 10px 25px !important;
}

.tableContainer {
  padding: 25px !important;
  background-color: var(--CL_GRAY_51);
}

.utcColumn {
  width: 32%;
}

.otherColumn {
  width: 17%;
}
