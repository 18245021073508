.root {
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}
.healthFilterButtonLabel {
  margin-right: 5px;
  align-self: center;
}

.legendContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px !important;
  width: 70px;
}

.legendContainer:hover {
  cursor: pointer;
}

.icon {
  height: 10px;
  width: 10px;
}
