.root {
  font-size: 14px;
  margin-top: 32px;
  margin-left: 50px;
  line-height: 24px;
}

.header {
 font-weight: 600;
 margin-bottom: 20px;
 font-size: 18px;
}
