.root {
  width: 420px !important;
  height: 320px !important;
}

.listboxContainer {
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.listbox {
  padding-left: 5px;
  padding-top: 5px;
  background-color: var(--CL_GRAY_51);
  border: 1px solid var(--CL_GRAY_127);
  width: 360px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.city:hover {
  cursor: pointer;
}

.footer {
  width: 100%;
}
