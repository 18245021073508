.root {
  padding: 8px !important;
  max-width: 300px;
  background: var(--CL_BLACK);
  border-left: 2px solid var(--CL_GRAY_77);
  font-size: var(--FS_SMALL);
  overflow: auto;
}

.root h1 {
  margin-bottom: 12px;
  font-size: var(--FS_XLARGE);
  font-weight: bold;
  text-align: center;
}

.root h2 {
  margin-bottom: 12px;
  font-size: var(--FS_LARGE);
  font-weight: bold;
  border-bottom: 1px solid var(--CL_GRAY_77);
}

.root .debugContainer {
  margin-bottom: 24px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.debugButton {
  height: 20px;
  padding: 0 8px;
  margin: 8px;
}
