.root {
  height: 100%;
  flex-wrap: nowrap;
}

.leftPanel {
  min-width: 200px;
  max-width: 200px;
}

:global(.NARROW-BODY) .leftPanel {
  min-height: calc(var(--NARROW_BODY_LOPA_HEIGHT) + 600px);
}

:global(.WIDE-BODY) .leftPanel {
  min-height: calc(var(--WIDE_BODY_LOPA_HEIGHT) + 600px);
}

.openAircraftButton {
  margin: -4px 0 0;
  padding: 0;
  line-height: var(--FS_LARGE);
  color: var(--white);
  font-size: var(--FS_XXLARGE);
  font-weight: bold;
  text-decoration: underline;
}

.container {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  padding: 0 25px !important;
  flex-wrap: nowrap;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.header {
  font-size: var(--FS_XXLARGE);
  font-weight: 700;
  padding: 18px;
}

.headerButton {
  margin-left: 14px;
}

.refreshButtonIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.iconButton {
  margin-left: 3px;
  padding: 0px;
  width: 20px;
}