.root,
.info {
  height: 100%;
  flex-wrap: nowrap;
  min-height: 590px;
}

.leftPanel {
  min-width: 200px;
  max-width: 200px;
}

.container {
  background-color: var(--CL_GRAY_89);
  flex-wrap: nowrap;
}
