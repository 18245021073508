.overviewContainer {
  background-color: var(--CL_GRAY_51);
  min-width: 1250px;
  padding: 20px;
  margin-bottom: 20px;
}

.title {
  color: var(--CL_WHITE);
  font-size: var(--FS_LARGE);
  font-weight: 700;
  margin-bottom: 20px;
}

.overviewTable {
  margin-bottom: 20px;
  max-height: 100%;
}
