.root {
  height: calc(100% - 60px);
  background-color: var(--CL_GRAY_51);
  padding: 0 25px;
}

.tableRow {
  width: 100%;
}

.tableHeaderRow {
  height: 60px;
  align-items: center;
}

.tableBodyRow {
  border: 1px solid var(--CL_GRAY_89);
}

.column {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px !important;
}

.label {
  font-weight: bold;
  border-right: 1px solid var(--CL_GRAY_127);
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.recordRow {
  border-bottom: 1px solid var(--CL_GRAY_89);
  height: 30px;
  align-items: center;
}

.recordRow:last-child {
  border-bottom: 0;
}

.header {
  font-size: var(--FS_XLARGE);
  font-weight: 600;
}

.tableContainer {
  padding: 25px 0 !important;
  background-color: var(--CL_GRAY_51);
}
