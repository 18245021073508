.root {
  display: flex;
  color: var(--CL_WHITE);
}

.root.selected {
  background-color: var(--CL_GRAY_217);
  color: var(--CL_BLACK);
  font-weight: 700;
  width: 78px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: -8px;
  margin-left: -15px;
}

.container {
  padding: 0;
  max-width: 15px;
  min-width: 15px;
}

.icon {
  height: 15px;
  max-width: 15px;
  border: 1px solid var(--CL_WHTE);
  border-radius: 2px;
  padding: 0;
}

.selected .icon {
  border: 1px solid var(--CL_BLACK);
}

.icon.operational {
  background-color: var(--CL_STATUS_OPERATIONAL);
}

.dimmed .icon.operational {
  background-color: var(--CL_STATUS_OPERATIONAL_DIMMED);
}

.icon.degraded {
  background-color: var(--CL_STATUS_DEGRADED);
}

.dimmed .icon.degraded {
  background-color: var(--CL_STATUS_DEGRADED_DIMMED);
}

.icon.unhealthy {
  background-color: var(--CL_STATUS_UNHEALTHY);
}

.dimmed .icon.unhealthy {
  background-color: var(--CL_STATUS_UNHEALTHY_DIMMED);
}

.icon.offline {
  background-color: var(--CL_STATUS_OFFLINE);
}

.dimmed .icon.offline {
  background-color: var(--CL_STATUS_OFFLINE_DIMMED);
}

.disconnectedLine {
  height: 15px;
  width: 3px;
  background-color: var(--CL_WHITE);
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  margin-left: 6px;
}

.label {
  margin-left: 5px;
  margin-top: 2px;
}

.dimmed .label {
  color: var(--CL_GRAY_127);
}

.dimmed .disconnectedLine {
  background-color: var(--CL_GRAY_119);
}
