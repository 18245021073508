.aircraftLine {
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  border-right: 0;
  height: 30px;
  display: flex;
  align-content: center;
  padding-left: 14px;
}

.aircraftLineSelected,
.aircraftLineSelected:hover {
  background-color: var(--CL_GRAY_217);
  color: var(--CL_BLACK);
  font-weight: 700;
}

.aircraftLine:hover {
  cursor: pointer;
  font-weight: 700;
}

.healthColumn {
  width: 10%;
  padding: 0;
}

.tailColumn {
  width: 20%;
  padding: 0;
}

.locationColumn {
  width: 30%;
  padding: 0;
  text-wrap: nowrap;
}

.statusColumn {
  width: 40%;
  padding: 0;
}

.statusIcon {
  height: 15px;
  width: 15px;
  padding: 0;
  border-radius: 2px;
  background-color: var(--CL_WHITE);
}

.statusIconSelected {
  border: 1px solid var(--CL_BLACK);
}

.disconnectedLine {
  height: 15px;
  width: 3px;
  background-color: var(--CL_WHITE);
  top: 7px;
  transform: rotate(45deg);
  margin-left: 6px;
}

.flightOperational {
  background-color: var(--CL_STATUS_OPERATIONAL);
}

.flightDegraded {
  background-color: var(--CL_STATUS_DEGRADED);
}

.flightUnhealthy {
  background-color: var(--CL_STATUS_UNHEALTHY);
}

.flightOffline {
  background-color: var(--CL_STATUS_OFFLINE);
}

.table {
  border: none;
}
