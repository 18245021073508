:root {
  /* breakpoints min-width */
  --xs: 320px;
  --sm: 500px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1500px;

  /* colors */
  --CL_BLACK: #000000;
  --CL_BLUE_1: #00B0F0;
  --CL_BLUE_2: #3CCDFF;
  --CL_BLUE_3: #009ED6;
  --CL_BLUE_4: #69D8FF;
  --CL_BLUE_5: #A6C1E2;
  --CL_GOLD_1: #BF9000;
  --CL_GRAY_51: #333333;
  --CL_GRAY_77: #4D4D4D;
  --CL_GRAY_89: #595959;
  --CL_GRAY_95: #5F5F5F;
  --CL_GRAY_100: #646464;
  --CL_GRAY_119: #777777;
  --CL_GRAY_127: #7F7F7F;
  --CL_GRAY_166: #A6A6A6;
  --CL_GRAY_178: #B2B2B2;
  --CL_GRAY_191: #BFBFBF;
  --CL_GRAY_217: #D9D9D9;
  --CL_GRAY_230: #e6e6e6;
  --CL_GRAY_242: #F2F2F2;
  --CL_GREEN_1: #92D050;
  --CL_GREEN_2: #C5E0B4;
  --CL_GREEN_3: #91cf50;
  --CL_GREEN_4: #496729;
  --CL_NAVY_1: #10172E;
  --CL_ORANGE_1: #FFAB85;
  --CL_ORANGE_1_60: #FFAB8560;
  --CL_ORANGE_2: #D76F29;
  --CL_PINK_1: #FF97FF;
  --CL_PINK_1_60: #FF97FF60;
  --CL_PURPLE_1: #BDBFFF;
  --CL_PURPLE_2: #8B8EFF;
  --CL_RED_1: #FF0000;
  --CL_RED_2: #590202;
  --CL_WHITE: #FFFFFF;
  --CL_YELLOW_1: #FFC000;
  --CL_YELLOW_2: #FFD966;
  --CL_YELLOW_3: #69551a;
  --PAGE_BACKGROUND_COLOR_STANDARD: var(--CL_GRAY_77);

  /* color aliases */
  --CL_STATUS_OPERATIONAL: var(--CL_GREEN_1);
  --CL_STATUS_DEGRADED: var(--CL_YELLOW_1);
  --CL_STATUS_UNHEALTHY: var(--CL_RED_1);
  --CL_STATUS_OFFLINE: var(--CL_GRAY_166);
  --CL_TEXT_PRIMARY: var(--CL_GRAY_242);
  --CL_BORDER_PRIMARY: var(--CL_GRAY_127);
  --CL_STATUS_OPERATIONAL_DIMMED: var(--CL_GREEN_4);
  --CL_STATUS_DEGRADED_DIMMED: var(--CL_YELLOW_3);
  --CL_STATUS_UNHEALTHY_DIMMED: var(--CL_RED_2);
  --CL_STATUS_OFFLINE_DIMMED: var(--CL_GRAY_77);

  /*font size*/
  --FS_XSMALL: 8px;
  --FS_SMALL: 11px;
  --FS_STANDARD: 12px;
  --FS_LARGE: 13px;
  --FS_XLARGE: 14px;
  --FS_XXLARGE: 16px;

  /* LOPA heights */
  --NARROW_BODY_LOPA_HEIGHT: 210px;
  --WIDE_BODY_LOPA_HEIGHT: 280px;

  --LOPA-margin-bottom: 20px;
}

html,
body,
#root {
  margin: 0;
  min-width: 1500px;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Verdana, Geneva, sans-serif;
  color: var(--CL_TEXT_PRIMARY);
  font-size: var(--FS_STANDARD);
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

input[type=checkbox],
input[type=radio] {
  accent-color: var(--CL_GRAY_100);
}

.field_1 {
  font-size: var(--FS_SMALL);
  color: var(--CL_GRAY_242);
}

.field_5 {
  font-size: var(--FS_STANDARD);
  font-weight: bold;
  color: var(--CL_WHITE);
}

.field_6 {
  font-size: var(--FS_STANDARD);
  color: var(--CL_WHITE);
}

.title_1 {
  font-size: var(--FS_XXLARGE);
  font-weight: bold;
}

.title_2 {
  font-size: var(--FS_LARGE);
  font-weight: bold;
}

.title_3 {
  font-size: var(--FS_STANDARD);
  font-weight: bold;
}

.title_4 {
  font-size: var(--FS_LARGE);
}

.label_1 {
  font-size: var(--FS_STANDARD);
}

.label_2 {
  font-size: var(--FS_STANDARD);
  font-weight: bold;
}

.button_call {
  font-size: var(--FS_SMALL);
  font-weight: bold;
  color: var(--CL_BLACK) !important;
  padding: 0 20px;
  background-color: var(--CL_GRAY_242) !important;
  border: 1px solid var(--CL_BLACK) !important;
  height: 24px;
}

.button_reg,
.button_reg_blank,
.button_foc {
  height: 24px;
  font-weight: bold;
  border: 1px solid var(--CL_GRAY_242) !important;
}

.button_reg,
.button_foc {
  font-size: var(--FS_SMALL);
  padding: 0 20px;
  color: var(--CL_GRAY_242);
  width: fit-content;
}

.button_reg_blank {
  width: 24px;
  padding: 0;
}

.button_reg,
.button_reg_blank {
  background-color: var(--CL_GRAY_95) !important;
}

.button_reg:hover,
.button_reg_blank:hover {
  background-color: var(--CL_GRAY_127) !important;
}

.button_reg:active,
.button_reg_blank:active {
  background-color: var(--CL_GRAY_95) !important;
}

.button_foc {
  background-color: var(--CL_BLUE_3) !important;
}

.button_foc:hover {
  background-color: var(--CL_BLUE_2) !important;
}

.button_foc:active {
  background-color: var(--CL_BLUE_1) !important;
}

.button_call:hover {
  background-color: var(--CL_WHITE) !important;
}

.button_call:active {
  background-color: var(--CL_GRAY_242) !important;
}

.skypulsetable_toggle_button {
  display: inline-block;
  padding: 2px 8px 2px 4px;
  margin-right: 8px;
  color: var(--CL_GRAY_242);
  text-decoration: underline;
  border-radius: 5px;
}

.skypulsetable_toggle_button:hover,
.skypulsetable_toggle_button.active {
  color: var(--CL_BLACK);
  background: var(--CL_GRAY_242);
}

*::-webkit-scrollbar {
  background-color: var(--CL_GRAY_89);
  border-left: 1px solid var(--CL_GRAY_127);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--CL_GRAY_178);
}
