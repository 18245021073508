.root {
  width: 100%;
  height: 100%;
}

.userDisplay {
  width: 35px;
  height: 35px;
  margin-left: -16px;
  margin-bottom: 3px;
  background: transparent;
  border-radius: 35px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  color: var(--CL_BLACK);
  padding-left: 6px;
  border-color: transparent;
}

.userDisplay:hover,
.userDisplay:active,
.userDisplay:focus {
  background: transparent !important;
  color: var(--CL_BLACK) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.menu {
  width: inherit;
  padding: 0;
  border: 1px solid var(--CL_WHITE);
  border-radius: 2px;
  margin: 5px 0 !important;
  background: var(--CL_GRAY_89);
}

.menuItem {
  background-color: inherit;
  color: var(--CL_WHITE);
  cursor: pointer;
  margin-top: -1px;
}

.menuItem:hover {
  color: var(--CL_WHITE);
}

.menuDivider {
  border-top: 2px solid var(--CL_WHITE);
  margin: 0;
}

.container {
  height: 35px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.icon {
  height: 24px;
  cursor: pointer;
}

.badge {
  position: absolute;
  background: red;
  padding: 0 5px;
  border-radius: 12px;
  right: 6px;
  top: -9px;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: bold;
}
