.root {
  margin: 6px;
}

.flip {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  margin-left: 0;
}

.label {
  margin-left: 6px;
  margin-top: 1px;
}
