.lopa {
  margin-bottom: var(--LOPA-margin-bottom);
}

.lopa:global(.NARROW-BODY) {
  height: var(--NARROW_BODY_LOPA_HEIGHT);
}

.lopa:global(.WIDE-BODY) {
  height: var(--WIDE_BODY_LOPA_HEIGHT);
}

.lruMap {
  background-color: var(--CL_GRAY_119);
  margin-right: 20px;
  max-width: 238px;
  min-width: 238px;
  padding: 24px 12px;
  height: 100%;
}

.seatMap {
  background-color: var(--CL_GRAY_119);
  max-width: 991px;
  min-width: 968px;
  padding: 12px 15px;
  height: 100%;
}

.seatRow {
  flex: 1;
  padding: 4px 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.lruRow {
  padding: 2px 0;
  display: flex;
  align-items: center;
}

.lruPositions {
  display: flex;
  justify-content: flex-end;
  margin: 12px -14px;
  font-size: var(--FS_XXLARGE);
}

.rowLabel {
  font-size: var(--FS_STANDARD);
  padding: 0;
  margin: 0;
}

.labelContainer {
  transform: rotate(270deg);
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-left: 5px;
}

.lru {
  display: flex;
  border: 1px solid var(--CL_BLACK);
  background-color: var(--CL_WHITE);
  margin-top: -1px;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  font-weight: 700;
  padding: 0;
  font-size: var(--FS_STANDARD);
}

.heatMapDisplay {
  mix-blend-mode: difference;
  font-size: var(--FS_XSMALL);
  font-weight: 300;
}

.lruMap .lru {
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin-top: 0;
}

.lruMap .lru:not(:last-child) {
  border-right: none;
}

.divider {
  margin: 0;
  width: 30px;
}

.aisle {
  border: 1px solid transparent;
  background-color: transparent;
  min-height: 30px;
  max-height: 30px;
  height: 30px;
  flex: 0 1;
}

.singleFaultStatus {
  background-color: var(--CL_YELLOW_1);
}

.multipleFaultStatus {
  background-color: var(--CL_RED_1);
}

.wapStatus {
  background-color: var(--CL_ORANGE_1);
}

.disconnectedStatus {
  background-color: var(--CL_PINK_1);
}

.seatResetSoft {
  background-color: var(--CL_PURPLE_1);
  color: var(--CL_BLACK);
  padding-bottom: 1px;
}

.seatResetHard {
  background-color: var(--CL_PURPLE_2);
  color: var(--CL_BLACK);
  padding-bottom: 1px;
}

.seatResetAffected {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.seatResetAffectedDot {
  height: 6px;
  width: 6px;
  background-color: var(--CL_PURPLE_2);
  border-radius: 4px;
}

.usageUnknown {
  background-color: var(--CL_GRAY_166);
  border: 1px solid var(--CL_GRAY_119);
}

.usageIdle {
  background-color: var(--CL_WHITE);
}

.usageMap {
  background-color: var(--CL_GREEN_2);
}

.usageAudio {
  background-color: var(--CL_YELLOW_2);
}

.usageVideo {
  background-color: var(--CL_GOLD_1);
}

.usageLiveTV {
  background-color: var(--CL_ORANGE_2);
}

.usageGame {
  background-color: var(--CL_BLUE_5);
}

.configSelected {
  background-color: var(--CL_BLUE_3);
}

.configAssociated {
  background-color: var(--CL_BLUE_4);
}

.heatMapAssociated {
  border: 2px solid var(--CL_WHITE);
}

.bluetoothIcon {
  height: 12px;
}

.seatContainer {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  background-color: transparent;
  margin: 0;
  flex: 1;
}

.blankSeatContainer {
  background-color: transparent;
  border: 1px solid transparent;
  margin: 0;
  flex: 1;
}
