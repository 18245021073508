.root {
  height: 100%;
  flex-wrap: nowrap;
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.logTable {
  margin-bottom: 20px;
}

.title {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.filterContainer {
  display: flex;
  align-items: center;
}

.filterRow {
  height: 60px;
  align-items: center;
  background-color: var(--CL_GRAY_51);
  padding: 0 20px;
  margin-bottom: 20px;
}

.note {
  font-size: 12px;
}

.tablePanel {
  background-color: var(--CL_GRAY_51);
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  height: calc(100% - 290px);
}

.dataContainer {
  background-color: var(--CL_GRAY_51);
  padding: 25px;
  height: calc(100% - 162px);
}

.titleContainer {
  margin-bottom: 18px;
  display: flex;
  justify-content: flex-end;
}
