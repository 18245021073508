.root {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bar {
  display: flex;
  position: absolute;
  margin: auto;
  z-index: 1;
  width: 80px;
  height: 80px;
}

.bar div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--CL_BLACK);
  animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.bar div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.bar div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.bar div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes spinner {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--CL_BLACK);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ellipsis div:nth-child(1) {
  left: 8px;
  animation: ellipsis1 0.6s infinite;
}
.ellipsis div:nth-child(2) {
  left: 8px;
  animation: ellipsis2 0.6s infinite;
}
.ellipsis div:nth-child(3) {
  left: 32px;
  animation: ellipsis2 0.6s infinite;
}
.ellipsis div:nth-child(4) {
  left: 56px;
  animation: ellipsis3 0.6s infinite;
}
@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

