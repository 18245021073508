.root {
  height: 100%;
}

.titleRow {
  height: 20px;
  margin-bottom: 12px;
}

.tableRow {
  height: 100%;
}

.tableRow.withTitle {
  height: calc(100% - 32px);
}

.row {
  min-height: 30px;
  align-items: center;
  padding-left: 12px;
  border: 1px solid var(--CL_BORDER_PRIMARY);
  border-top: none;
}

.loadingRow {
  height: 80px;
  align-items: center;
  padding-left: 12px;
  border: 1px solid var(--CL_BORDER_PRIMARY);
  border-top: none;
}

.tableBorder {
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
}

.row:first-child {
  border-top: 1px solid var(--CL_BORDER_PRIMARY);
}

.row:last-child {
  border-bottom: transparent;
}

.allowAction {
  cursor: pointer;
}

.allowAction:hover {
  background-color: var(--CL_GRAY_89);
}

.headersRow,
.headersRow:hover {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  cursor: default;
}

.headersRow .column {
  font-weight: bold;
}

.sortable {
  text-decoration: underline;
  cursor: pointer;
}

.ascdesIcon {
  height: 12px;
  margin-left: 4px;
}

.rowContainer {
  max-height: 100%;
  overflow-y: overlay;
}

.rowContainer.withHeaders {
  max-height: calc(100% - 30px);
}

.row.selected {
  background-color: var(--CL_BLUE_3);
}

.column {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tinyColumn {
  width: 5%;
}

.xsmallColumn {
  width: 10%;
}

.smallColumn {
  width: 20%;
}

.mediumColumn {
  width: 30%;
}

.largeColumn {
  width: 40%;
}

.xlargeColumn {
  width: 50%;
}

.outlier {
  background: var(--CL_YELLOW_2);
  color: var(--CL_BLACK);
}

.exportButton {
  color: var(--CL_WHITE);
  font-weight: bold;
  background-color: var(--CL_BLUE_3);
  padding: 4px 20px;
  border: 1px solid var(--CL_WHITE);
  border-radius: 3px;
}

.exportButton:hover {
  color: var(--CL_WHITE);
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 12px;
}
