.root {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0;
  overflow-y: hidden;
}

.headerRow {
  min-height: 50px;
}

.bodyWithHeader {
  min-height: calc(100% - 50px);
}

.bodyWithHeaderFooter {
  min-height: calc(100% - 100px);
}

.body {
  height: 100%;
  overflow-y: scroll;
}
