.container {
  background-color: var(--CL_BLACK);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
}

.message {
  font-size: 32px;
  font-weight: 700;
}
