.container {
  background-color: var(--CL_BLACK);
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  display: flex;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
}

.logo img {
  width: 150px;
  height: 40px;
}

.menuItem {
  margin-left: 45px;
  padding: 0;
  font-size: var(--FS_LARGE);
  color: var(--CL_WHITE);
  line-height: 50px;
  text-align: center;
}

.menuItem:hover {
  color: var(--CL_BLUE_4);
  cursor: pointer;
}

.menuItem:active {
  color: var(--CL_BLUE_4);
  font-weight: bold;
  cursor: pointer;
}

.menuItem.active {
  color: var(--CL_BLUE_4);
  font-weight: bold;
  cursor: default;
}

.orgLogo {
  margin-right: 40px;
}

.orgLogo img {
  width: 170px;
  height: 40px;
}

.time {
  margin-right: 40px;
  padding: 0;
  font-weight: bold;
  color: var(--CL_WHITE);
}
