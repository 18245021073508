.overlayContainer {
  height: 90%;
  width: 1400px;
  padding: 0;
}

.bodyClassName {
  height: 100%;
}

.dialog {
  max-width: 1400px;
}

.root {
  padding: 0 25px 25px;
  height: 100%;
  background-color: var(--CL_GRAY_89);
}

.headerRow {
  height: 60px;
  align-items: center;
}

.headerContainer {
  font-size: var(--FS_XXLARGE);
  font-weight: 700;
  padding: 18px;
}

.headerButton {
  margin-left: 14px;
}

.filterRow {
  height: 60px;
  align-items: center;
  background-color: var(--CL_GRAY_51);
  margin-bottom: 20px;
}

.filterList{
  display: flex;
}

.tableRow {
  height: calc(100% - 140px);
}

.tableContainer {
  height: 100%;
  padding: 25px !important;
  background-color: var(--CL_GRAY_51);
}

.eventsFilterButton {
  width: 120px;
  text-align: left;
  font-weight: 400;
  height: 20px;
  padding: 0 8px;
}

.lruFilterButton {
  width: 150px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  height: 20px;
  padding: 0 8px;
}

.removeButton {
  height: 20px;
}

.buttonItemLabel {
  padding-top: 4px;
}

.divider {
  width: 1px;
  background-color: var(--CL_GRAY_119);;
  height: 70%;
}

.options {
 padding: 0 16px;
}
