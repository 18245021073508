.root {
  font-size: 14px;
  margin-top: 32px;
  margin-left: 50px;
  line-height: 24px;
}

.content {
  width: 550px;
}

.header {
 font-weight: 600;
 margin-bottom: 20px;
}

.footer {
  width: 100%;
  justify-content: space-between;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loadingMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height : 80px;
}

.spinner {
  display: flex;
  height : 80px;
  align-items: center;
  justify-content: center;
}