.header, .footer {
  height: 45px;
  align-items: center;
}
.content {
  width: 320px;
  height: 320px;
  padding: 0px 15px;
  background-color: var(--CL_GRAY_89);
  border: 1px solid var(--CL_WHITE);
}

.position {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
