.root {
  font-size: 14px;
  margin-top: 32px;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  width: 100%;
}

.bodyClassName {
  height: calc(100% - 50px)
}

.overlayRoot {
  height: 85%;
  min-width: 1300px;
  width: 1300px;
  padding: 25px 25px 25px 25px !important;
  background-color: var(--CL_GRAY_89);
}

.tableContainer {
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
}

.tablestyles {
  height: 100%;
}

.header {
 font-weight: 600;
 margin-bottom: 20px;
}

.footer {
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loadingMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height : 80px;
}

.spinner {
  display: flex;
  height : 80px;
  align-items: center;
  justify-content: center;
}

.link {
  background-color: transparent;
  text-decoration: underline;
  color: var(--CL_GRAY_217);
  border: none;
}

.link:hover,
.link:active,
.link:focus {
  background-color: transparent !important;
  text-decoration: underline;
  border: none !important;
  color: var(--CL_GRAY_242) !important;
  box-shadow: none !important;
}

.tableHeader {
  height: 50px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  line-height: 12px;
  margin-top: 24px;
  margin-left: -8px;
  margin-right: 12px;
}

.checkboxButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.emailText {
  margin: -12px 0 12px 0;
}

.icon {
  height: 20px;
  margin: 4px;
  cursor: pointer;
}
