.root {
  height: 100%;
  flex-wrap: nowrap;
}

.leftPanel {
  min-width: 200px;
  max-width: 200px;
}

.container {
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
  flex-wrap: nowrap;
}
