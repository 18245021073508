.root {
  width: 100%;
}

.rightPanelInfo {
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  margin: 8px 20px;
  padding: 12px 0;
}

.header {
  font-size: var(--FS_LARGE);
}

.nowrap {
  white-space: nowrap;
}

strong.indent {
  margin-left: 12px;
}

.charts {
  margin: 8px;
}

.charts .col {
  padding: 0 7px;
}

.barchart {
  border: 1px solid var(--CL_BORDER_PRIMARY);
}

.chartLabel {
  display: block;
  text-align: center;
  margin-top: 5px;
}

/* not so common */
span.aircraftStatus,
span.aircraftStatus:hover {
  color: var(--CL_WHITE);
  border: 0;
  outline: 0;
  font-weight: 700;
  cursor: default !important;
}

.aircraftStatusPercentage {
  margin-left: 6px;
  font-weight: 300;
}

span.Operational,
span.Operational:hover,
span.Operational:active {
  background: var(--CL_STATUS_OPERATIONAL);
}

span.Degraded,
span.Degraded:hover,
span.Degraded:active {
  background: var(--CL_STATUS_DEGRADED);
}

span.Unhealthy,
span.Unhealthy:hover,
span.Unhealthy:active {
  background: var(--CL_STATUS_UNHEALTHY);
}

span.Offline,
span.Offline:hover,
span.Offline:active {
  background: var(--CL_STATUS_OFFLINE);
}

.openAircraftContainer {
  text-align: right;
}

.openAircraftButton {
  margin: 0;
  padding: 0;
  color: var(--white);
  font-weight: bold;
  text-decoration: underline;
}

.openFlightButton {
  padding: 0 10px;
}

.noSpace {
  padding: 0;
}

.flightGraphContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chartLabelDep {
  margin-right: 64px;
}
