.flightTracker {
  text-align: center;
}

.bar {
  position: relative;
  height: 8px;
  width: 90%;
  left: 5%;
  background-color: var(--gray);
  font-size: 0.9rem;
  margin-top: 16px;
  margin-bottom: 23px;
}

.bar > .progress {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: var(--blue);
}

.bar > .remaining {
  position: absolute;
  right: 0;
  height: 100%;
}

.bar > .progress > .timeInAir {
  position: relative;
  bottom: 20px;
}

.bar > .progress > .icon {
  position: relative;
  bottom: 24px;
  left: 90%;
  width: 25px;
  height: 25px;
}

.bar > .remaining > .estimatedTimeRemaining {
  position: relative;
  bottom: 20px;
}

.depart {
  position: absolute;
  top: -5px;
  left: -15px;
  display: inline-block;
  border-radius: 50%;
  border: 5px solid var(--blue);
  height: 18px;
  width: 18px;
}

.depart > span {
  position: relative;
  top: 18px;
  left: -5px;
  font-weight: 600;
}

.arrive {
  position: absolute;
  top: -5px;
  right: -16px;
  display: inline-block;
  border-radius: 50%;
  border: 5px solid var(--gray);
  height: 18px;
  width: 18px;
}

.arrive > span {
  position: relative;
  top: 18px;
  left: -10px;
  font-weight: 600;
}
