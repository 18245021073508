.root {
  height: 100%;
  width: 100%;
}

.title {
  padding: 15px 20px;
}

.divider {
  margin-right: 15px;
}

.panel {
  justify-content: center;
}

.filterActions,
.selectContainer,
.textFieldContainer {
  justify-content: flex-end;
}

.selectContainer,
.textFieldContainer {
  margin-bottom: 12px;
}

.select,
.textField {
  height: 20px;
  background-color: var(--CL_GRAY_95);
  color: var(--CL_GRAY_242);
  padding: 0;
}

.select:focus,
.textField:focus {
  background-color: var(--CL_GRAY_95);
  color: var(--CL_GRAY_242);
}

.select {
  width: 150px;
}

.labelText {
  margin: 0;
}

.textField {
  width: 45px;
  padding-left: 5px;
}

.textFieldLabel {
  margin-right: 5px !important;
  padding: 0;
}

.iconContainer {
  display: flex;
  margin-left: 5px;
}

.icon {
  height: 20px;
  width: 20px;
  align-self: center;
}

.icon:hover {
  cursor: pointer;
}

.selectLabel {
  margin-right: 5px;
  max-width: 80px;
  text-align: end;
  padding: 0;
}
