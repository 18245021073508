.root {
  height: 100%;
  flex-wrap: nowrap;
  padding: 20px 25px 0 25px !important;
  background-color: var(--CL_GRAY_89);
}

.overlayRoot {
  height: 85%;
  width: 1300px;
  padding: 25px 25px 25px 25px !important;
  background-color: var(--CL_GRAY_89);
}

.body {
  height: 100%;
  padding: 25px 0 0 0 !important;
}

.header {
  height: 20px;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;
  font-size: var(--FS_XXLARGE);
  font-weight: 700;
}

.buttonContainer {
  display: flex;
  justify-content: right;
}

.closeButton {
  border-radius: 0;
  border: 1px solid var(--CL_WHITE);
  background-color: var(--CL_BLUE_3);
  padding: 6px 18px;
  font-weight: 600;
  font-size: 14px;
}

.reportButton {
  border-radius: 0;
  border: 1px solid var(--CL_GRAY_242) !important;
  background-color: var(--CL_GRAY_95);
  color: var(--CL_GRAY_242);
  padding: 6px 18px;
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
}

.overviewContainer {
  background-color: var(--CL_GRAY_51);
  min-width: 1250px;
  padding: 20px;
  margin-bottom: 20px;
}

.title {
  color: var(--CL_WHITE);
  font-size: var(--FS_LARGE);
  font-weight: 700;
}

.eventContainer {
  height: calc(100% - 200px);
  background-color: var(--CL_GRAY_51);
  padding: 20px;
}

.tableContainer {
  background-color: var(--CL_GRAY_51);
  padding: 0 0 0 25px;
  height: calc(100% - 120px);
  max-height: calc(100% - 120px);
}

.tableTitle {
  height: 50px;
  align-items: center;
  margin-right: 14px;
  margin-bottom: 12px;
}

.tableCol {
  height: 100%;
}

.overviewTable {
  margin-top: 12px;
  max-height: 100%;
}

.eventsTable {
  height: calc(100% - 65px);
}

.filterList{
  display: flex;
}

.eventsFilterButton {
  width: 120px;
  text-align: left;
  font-weight: 400;
  height: 20px;
  padding: 0 8px;
}
