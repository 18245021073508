.root {
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
}

.healthSelectionBar {
  padding: 0 10px;
  width: 180px;
  height: 27px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 4px;
}

.healthSelectionBar:hover,
.status:hover {
  cursor: pointer;
}

.healthSelectionBar:last-child {
  margin-bottom: 0;
}

.selected {
  border: none;
}

.unselected {
  border: 1px solid var(--CL_GRAY_191);
}

.statusContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status {
  margin: 0;
}

.footer {
  width: 100%;
  justify-content: space-between;
}
