.root {
  padding: 0;
  background-color: var(--CL_GRAY_51);
  min-height: 100%;
}

.leftPanelContainer {
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  padding: 20px !important;
}

.flightNumber {
  font-size: var(--FS_XXLARGE);
  font-weight: bold;
}

.text {
  font-size: var(--FS_XLARGE);
}

.menuTitle {
  font-size: var(--FS_LARGE);
  font-weight: bold;
  margin-bottom: 8px;
}

.menuItem {
  margin-left: 12px;
  margin-right: 12px;
  padding: 6px 12px;
  font-size: var(--FS_LARGE);
  background-color: var(--CL_GRAY_89);
  border-bottom: 1px solid var(--CL_BORDER_PRIMARY);
  cursor: pointer;
}

.menuItemRow:last-of-type .menuItem {
  border-bottom: 0;
}

.menuItemActive {
  background-color: var(--CL_GRAY_217);
  color: var(--CL_BLACK);
  font-weight: bold;
}
