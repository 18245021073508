.overlayRoot {
  height: 85%;
  min-width: 1300px;
  width: 1300px;
  padding: 25px 25px 25px 25px !important;
  background-color: var(--CL_GRAY_89);
}

.bodyClassName {
  height: calc(100% - 50px)
}

.root {
  font-size: 14px;
  margin-top: 32px;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  width: 100%;
}

.tableContainer {
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
}

.tablestyles {
  height: 100%;
}

.footer {
  width: 100%;
  justify-content: right;
  margin-top: 12px;
}

.header {
  font-size: medium;
  font-weight: 600;
  padding-left: 12px;
}

.notificationContainer {
  margin: 12px;
  background: var(--CL_GRAY_51);
  height: 100%;
  overflow-y: scroll;
  font-size: medium;
  padding: 12px;
}

.textBlock {
  margin: 12px 0;
}

.notificationBlock {
  border-bottom: 1px solid var(--CL_WHITE);
  padding: 12px;
  display: flex;
  flex-direction: column;
}
