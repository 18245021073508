.root {
  height: calc(100% - 60px);
}

.tableContainer {
  background-color: var(--CL_GRAY_51);
  margin-bottom: 24px;
}

:global(.NARROW-BODY) .tableContainer {
  height: calc(100% - var(--NARROW_BODY_LOPA_HEIGHT) - var(--LOPA-margin-bottom));
  max-height: calc(100% - var(--NARROW_BODY_LOPA_HEIGHT) - var(--LOPA-margin-bottom));
}

:global(.WIDE-BODY) .tableContainer {
  height: calc(100% - var(--WIDE_BODY_LOPA_HEIGHT) - var(--LOPA-margin-bottom));
  max-height: calc(100% - var(--WIDE_BODY_LOPA_HEIGHT) - var(--LOPA-margin-bottom));
}

.table {
  padding: 25px;
  background-color: var(--CL_GRAY_51);
  height: inherit;
  max-height: 100%;
}

.multiTable {
  padding: 12px 25px;
  background-color: var(--CL_GRAY_51);
  height: 50%;
  min-height: 50%;
}

.lopaDetailsRow {
  gap : 18px;
  margin-right: 0px;
}

.lopaDetailsCol {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  font-size: var(--FS_STANDARD);
  font-weight: normal;
}

.lopaItemContainer {
  display: flex;
  gap : 8px;
}

.lru {
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin-top: 0;
}

.lruItem {
  background-color: var(--CL_BLUE_3);
}
.portItem {
  background-color: var(--CL_BLUE_4);
}

.table > :global(.col) {
  overflow-y: auto;
  overflow-x: hidden;
}
