.root {
  height: 100%;
  flex-wrap: nowrap;
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.title {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.filterRow {
  height: 60px;
  align-items: center;
  background-color: var(--CL_GRAY_51);
  padding: 0 20px;
  margin-bottom: 20px;
}

.filterContainer {
  align-items: center;
}

.tableRow {
  background-color: var(--CL_GRAY_51);
  padding: 25px;
  height: calc(100% - 120px);
}

.textContainer {
  margin: 12px 40px 0 20px;
  max-width: 80px;
}

.textField {
  height: 20px;
  background-color: var(--CL_GRAY_95);
  color: var(--CL_GRAY_242);
  padding: 0;
}

.textFieldLabel {
  margin-right: 5px !important;
  padding: 0;
}

.textField:focus {
  background-color: var(--CL_GRAY_95);
  color: var(--CL_GRAY_242);
}

.filterActions {
  justify-content: flex-end;
  width: 170px;
}
