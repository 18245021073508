.flightWrapper {
  height: calc(100% - 60px);
}

.infoRow {
  display: flex;
  flex: 1;
  height: 100%;
}

:global(.NARROW-BODY) .infoRow {
  height: calc(100% - var(--NARROW_BODY_LOPA_HEIGHT) - 40px);
}

:global(.WIDE-BODY) .infoRow {
  height: calc(100% - var(--WIDE_BODY_LOPA_HEIGHT) - 40px);
}

.overview,
.lopaKey,
.event {
  margin: 0 10px;
  background-color: var(--CL_GRAY_51);
  overflow-y: auto;
  min-height: 500px;
  max-height: 100%;
}

.overview {
  width: 320px;
  margin-left: 0;
}

.lopaKey {
  width: 460px;
}
