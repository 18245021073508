.root {
  height: 100%;
  flex-wrap: nowrap;
}
.leftPanel {
  width: 300px;
}

.body {
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.bodyHeaderRow {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.fleetSelectionRow {
  background-color: var(--CL_GRAY_51);
  height: 60px;
  min-width: 1250px;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}

.shipListRow {
  height: calc(100% - 120px);
  padding: 25px;
  background-color: var(--CL_GRAY_51);
}

.tableContainer {
  width: 500px;
  height: 100%;
}

.flightLink {
  padding-right: 8px;
  text-decoration: underline;
  cursor: pointer;
}
